import React, { useState, useEffect } from "react";
import "./gallerysection.css";
import StringData from "../../String.json";

const GallerySection = () => {
  const [data, setData] = useState(StringData.gallerysection.gallerydata);
  useEffect(() => {
    if (
      StringData &&
      StringData.gallerysection &&
      StringData.gallerysection.gallerydata
    ) {
      setData(StringData.gallerysection.gallerydata);
    }
  }, [data]);
  return (
    <>
      <div className="container-fluid p-0">
        <div class="image-gallery-container">
          <div class="image-gallery">
            {data.map((items) => (
              <div key={items.galleryId} class="image-item">
                <img src={items.galleryImage} alt="seed quality" />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default GallerySection;
